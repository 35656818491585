import React, { createContext, useContext, useState, } from 'react'
import { service } from 'services/index';
import { useCompany } from 'hooks/useCompany';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import useAuth from 'context/useAuth';

export const OpportunityContext = createContext({})

export function OpportunityProvider({children}) {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [opportunity, setOpportunity] = useState({})
  const [opportunities, setOpportunities] = useState([])
  const [courses, setCourses] = useState([])
  const [exams, setExams] = useState([])
  const [errorSeeOpportunities, setErrorSeeOpportunities] = useState(false)

  function getOpportunity(id) {
    service
      .opportunity
      .find(id)
      .then(setOpportunity)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  function getOpportunityByCandidate(id) {
    service
      .opportunity
      .findByCandidate(id)
      .then(setOpportunity)
      .catch(setError)
      .finally(() => setLoading(false))
  }

  function getOpportunityCourses(id) {
    service
      .courses
      .listByOpportunity(id)
      .then(setCourses)
      .catch(setError)
  }

  function getOpportunityExams(id) {
    service
      .exam
      .listByOpportunity(id)
      .then(setExams)
      .catch(setError)
  }

  function listByCandidate(params) {
    service
      .opportunity
      .listByCandidate(params)
      .then(({results}) => setOpportunities(results))
      .catch(setError)
      .finally(() => setLoading(false))
  }

  function createOpportunity(data) {
    return service.opportunity.create(data)
  }

  function updateOpportunity(opportunityId, data) {
    return service.opportunity.update(opportunityId, data)
  }

  function averageWage(position_id, zipcode) {
    return service.opportunity.averageWage(position_id, zipcode)
  }

  const value = {
    loading,
    setLoading,
    error,
    setError,
    opportunity,
    getOpportunity,
    getOpportunityByCandidate,
    getOpportunityCourses,
    getOpportunityExams,
    createOpportunity,
    updateOpportunity,
    listByCandidate,
    opportunities,
    courses,
    exams,
    errorSeeOpportunities,
    averageWage,
  }

  return (
    <OpportunityContext.Provider value={value}>
      {children}
    </OpportunityContext.Provider>
  )
}

export default function useOpportunity() {
  const context = useContext(OpportunityContext)
  const {
    loading,
    setLoading,
    error,
    setError,
    opportunity,
    getOpportunity,
    getOpportunityByCandidate,
    getOpportunityCourses,
    getOpportunityExams,
    createOpportunity,
    updateOpportunity,
    listByCandidate,
    opportunities,
    courses,
    exams,
    errorSeeOpportunities,
    averageWage,
  } = context;

  return {
    loading,
    setLoading,
    error,
    setError,
    opportunity,
    getOpportunity,
    getOpportunityByCandidate,
    getOpportunityCourses,
    getOpportunityExams,
    createOpportunity,
    updateOpportunity,
    listByCandidate,
    opportunities,
    courses,
    exams,
    errorSeeOpportunities,
    averageWage,
  };
}
