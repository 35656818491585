import React from 'react'

export default function SpoonForkCrossedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="35" viewBox="0 0 50 45">
      <g id="_002-spoon-and-fork-crossed" data-name="002-spoon-and-fork-crossed" transform="translate(0 -3.002)">
        <g id="Group_1641" data-name="Group 1641" transform="translate(0 3.002)">
          <path id="Path_10189" data-name="Path 10189" d="M37.143,45.737c.47.471,1.855-.149,2.326-.62l.776-.776c.471-.471,1.092-1.856.62-2.327L29.2,30.947l-3.049,3.212Z" transform="translate(-3.206 -6.428)" fill="#41c0f0"/>
          <path id="Path_10190" data-name="Path 10190" d="M14.6,16.916c1.517-2.638.715-5.837-1.674-8.226C10.1,5.867,4.005,3.106,1.183,5.93s-.062,8.92,2.76,11.743c2.344,2.344,5.466,3.161,8.077,1.758l5.526,5.824,2.919-2.77Z" transform="translate(0 -3.216)" fill="#41c0f0"/>
          <path id="Path_10191" data-name="Path 10191" d="M29.755,16.621,6.608,38.589c-.471.471.149,1.856.62,2.327L8,41.691a3.173,3.173,0,0,0,1.826.788.7.7,0,0,0,.5-.168L32.379,19.078a5.416,5.416,0,0,0,1.914.533,7.189,7.189,0,0,0,5.635-2.318c5.559-5.559,6.557-6.783,4.85-8.924l-6.562,6.289a.807.807,0,0,1-.562.236.678.678,0,0,1-.486-.2l-.175-.175a.682.682,0,0,1-.2-.487.818.818,0,0,1,.242-.567l5.957-6.989-.426-.426c-.255-.255-.493-.494-.718-.721l-7.015,5.984a.785.785,0,0,1-.541.218.678.678,0,0,1-.486-.2l-.175-.175a.682.682,0,0,1-.2-.487.818.818,0,0,1,.242-.567l6.258-6.53c-2.019-1.467-3.246-.244-8.608,5.118a7,7,0,0,0-2.245,6.145A5.561,5.561,0,0,0,29.755,16.621Z" transform="translate(-0.789 -3.002)" fill="#41c0f0"/>
        </g>
      </g>
    </svg>
  )
}
