import React from 'react'

export default function BusIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70.215" height="38.366" viewBox="0 0 70.215 38.366">
      <g id="_003-bus" data-name="003-bus" transform="translate(-20.05 -7.498)">
        <path id="Path_10192" data-name="Path 10192" d="M23.242,130.555a1.6,1.6,0,0,1,1.6-1.6H36.008v12.766H23.242Zm15.958-1.6H51.966v12.766H39.2Zm15.958,0H67.924v12.766H55.158Zm15.958,0H81.728a3.166,3.166,0,0,1,3.112,2.633l1.835,11.331a27.279,27.279,0,0,1,.319,3.591H75.9l-4.787-4.787Zm-51.066,0v28.724a3.2,3.2,0,0,0,3.192,3.192h.16a9.832,9.832,0,0,1-.16-1.6,7.979,7.979,0,1,1,15.958,0,9.822,9.822,0,0,1-.16,1.6H68.084a9.833,9.833,0,0,1-.16-1.6,7.979,7.979,0,1,1,15.958,0,9.822,9.822,0,0,1-.16,1.6h3.351a3.2,3.2,0,0,0,3.192-3.192V147.47a36.71,36.71,0,0,0-.4-5.107l-1.835-11.41a6.417,6.417,0,0,0-6.3-5.266H23.242a3.324,3.324,0,0,0-3.192,3.272Z" transform="translate(0 -118.189)" fill="#41c0f0" fill-rule="evenodd"/>
        <path id="Path_10193" data-name="Path 10193" d="M369.058,319.5a5.585,5.585,0,1,1-5.585-5.585A5.585,5.585,0,0,1,369.058,319.5Z" transform="translate(-287.57 -279.219)" fill="#41c0f0" fill-rule="evenodd"/>
        <path id="Path_10194" data-name="Path 10194" d="M68.758,319.5a5.585,5.585,0,1,1-5.585-5.585A5.585,5.585,0,0,1,68.758,319.5Z" transform="translate(-31.952 -279.219)" fill="#41c0f0" fill-rule="evenodd"/>
      </g>
    </svg>
  )
}
