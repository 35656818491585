import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    background: "#f3f3f3",
    padding: "32px",
    marginLeft: "32px",
    marginRight: "32px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  buttonContainer: {
    margin: "28px",
  },
  titleModal: {
    margin: "28px",
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
  },
  titlePosition: {
    marginLeft: "10px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  paymentTotal: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textDecorationLine: "underline",
  },
  paymentValue: {
    fontWeight: "bold",
    opacity: 0.4,
    textDecorationLine: "underline",
  },
}));

export default style;
