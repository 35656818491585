import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme, Dialog } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: 'auto',
    },
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    textAlign: 'center',
    padding: '10px',
    color: '#000029',
  },
  img: {
    height: 355,
    width: 600,
    overflow: 'hidden',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      height: 300,
      width: '100%',
      overflow: 'hidden',
    },
  },
  footer: {
    color: '#0b2c46',
    background: '#000029',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#0b2c46',
  },
}))

function Carousel({
  open,
  handleClose,
  images,
  index,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(index)

  const maxSteps = images.length - 1

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  if (!open) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
    >
      <div className={classes.root}>
        {images && (
          <img
            className={classes.img}
            src={images[activeStep]?.image}
            alt={images[activeStep]?.description}
          />
        )}
        {images[activeStep]?.description && (
          <Paper square elevation={0} className={classes.description}>
            <Typography>{images[activeStep]?.description}</Typography>
          </Paper>
        )}
        <div className={classes.actionButtons}>
          <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            className={classes.actionButtons}
          >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Anterior
          </Button>
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps}
            className={classes.actionButtons}
          >
            Próximo
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

Carousel.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })),
  index: PropTypes.number.isRequired,
}

Carousel.defaultProps = {
  images: [],
  open: false,
}

export default Carousel
