import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FailCircle from "components/icons/FailCircle";
import Slide from "@material-ui/core/Slide";
import { withStyles, Typography, Grid } from "@material-ui/core";
import useStyles from "./style";

const BlueButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#41C0F0",
    color: theme.palette.success.contrastText,
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginLeft: "10px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#0B2C46",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      "& span": {
        fontSize: "12px",
      },
    },
  },
}))(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalFailOpportunity({ open, onClose }) {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="confirm-dialog-description"
    >
      <div>
        <div className={classes.container}>
          <FailCircle size={80} />
          <Typography variant="h6" className={classes.title}>
            Ops! Algo deu errado na candidatura
          </Typography>
          <div className={classes.body}>
            <div className={classes.contentBody}>
              <Typography className={classes.titlePosition}>
                Tente novamente mais tarde, se o erro persistir entre em contato
                com nosso suporte.
              </Typography>
            </div>
          </div>
          <Grid item md={6} xs={12} className={classes.blueButton}>
            <BlueButton onClick={onClose} size="large" variant="contained">
              ENTENDI
            </BlueButton>
          </Grid>
        </div>
      </div>
    </Dialog>
  );
}
