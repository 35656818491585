import React from 'react'
import PropTypes from 'prop-types'
import { OpportunityProvider } from 'containers/opportunity/hooks/useOppotunity'
import Container from './Container'

function FeedContainer({ company, positions }) {
  return (
    <OpportunityProvider>
      <Container company={company} positions={positions} />
    </OpportunityProvider>
  )
}

FeedContainer.propTypes = {
  company: PropTypes.number,
  positions: PropTypes.arrayOf(PropTypes.number),
}

FeedContainer.defaultProps = {
  company: null,
  positions: null,
}

export default FeedContainer
