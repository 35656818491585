import React from 'react'
import {
  Avatar,
  Button,
  Grid,
  Typography,
  withStyles,
  Box,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import BusIcon from 'Components/icons/Bus'
import SpoonForkCrossedIcon from 'Components/icons/SpoonForkCrossed'
import formatShortAddress from 'utils/formatShortAddress'
import ModalConfirm from 'components/ModalConfirm'
import ModalSuccessOpportunity from 'components/ModalSuccessOpportunity'
import ModalFailOpportunity from 'components/ModalFailOpportunity'
import AppsIcon from '@material-ui/icons/Apps'
import { useRouter } from 'next/router'
import { DefaultButton } from '@w550/worc-ui'
import EmptyMessageFeedIcon from 'Components/icons/EmptyMessageFeed'
import RocketGreenIcon from 'Components/icons/RocketGreen'
import { currencyFormat } from 'providers/utils'
import useStyles from './style'
import Loading from '../Loading'
import ButtonPosition from './components/ButtonPosition'

const GreenButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    fontSize: 19,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
    [theme.breakpoints.down('sm')]: {
      '& span': {
        fontSize: '12px',
      },
      '& svg': {
        fontSize: '13px',
      },
    },
  },
}))(Button)

const BlueButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#41C0F0',
    color: theme.palette.success.contrastText,
    fontSize: 17,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    width: '100%',
    '&:hover': {
      backgroundColor: '#0B2C46',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      '& span': {
        fontSize: '12px',
      },
    },
  },
}))(Button)

const FeedCandidate = ({
  loadingOpportunity,
  listOpportunityBoard,
  handleCandidateApply,
  shareOpportunity,
  setOpportunity,
  opportunity,
  profileUnfinished,
  positions,
  setPositionsSelected,
  showModalSuccess,
  setShowModalSuccess,
  showModalFail,
  setShowModalFail,
}) => {
  const classes = useStyles()
  const router = useRouter()

  if (loadingOpportunity) {
    return <Loading />
  }

  const handleFeedCandidate = (info) => (info.has_course || info.has_exam ? router.push(`/candidato/vagas/${info.id}/`) : setOpportunity(info))

  if (profileUnfinished) {
    return (
      <section className={classes.main}>
        <Typography className={[classes.mainTitle, classes.white]} variant="h6">
          Mural de vagas
        </Typography>
        <section className={classes.whiteBg}>
          <Grid container spacing={2}>
            <Grid item container direction="row" md={12} xs={12} sm={12}>
              <Grid item md={2} xs={4} sm={2}>
                <RocketGreenIcon />
              </Grid>
              <Grid item container direction="column" md={10} xs={8} sm={10}>
                <Typography className={classes.subTitle} variant="h5">
                  Complete seu perfil para visualizar vagas
                </Typography>
                <Typography variant="body1">
                  Para visualizar vagas é necessário completar seu perfil e
                  ralizar pelo menos um curso e uma prova.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              md={12}
              xs={12}
              sm={12}
              spacing={2}
            >
              <Grid item md={4} xs={4} sm={4} className={classes.blueButton}>
                <DefaultButton
                  label="Preencher perfil"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  handleOnClick={() => router.push('/editar/cadastro-do-candidato')}
                />
              </Grid>
              <Grid item md={4} xs={4} sm={4} className={classes.blueButton}>
                <DefaultButton
                  label="Fazer um curso"
                  color="primary"
                  fullWidth
                  handleOnClick={() => router.push('/cursos')}
                />
              </Grid>
              <Grid item md={4} xs={4} sm={4} className={classes.blueButton}>
                <DefaultButton
                  label="Fazer uma prova"
                  color="primary"
                  fullWidth
                  handleOnClick={() => router.push('/provas')}
                />
              </Grid>
            </Grid>
          </Grid>
        </section>
      </section>
    )
  }

  return (
    <section className={classes.main}>
      <Typography className={[classes.mainTitle, classes.white]} variant="h6">
        Mural de vagas
      </Typography>
      <ButtonPosition
        setPositionsSelected={setPositionsSelected}
        positions={positions}
      />
      {listOpportunityBoard.length ? (
        listOpportunityBoard.map(({ company, address, ...info }) => (
          <section key={info.id} className={classes.whiteBg}>
            <Grid container spacing={3} className={classes.content}>
              <Grid item md={12} xs={12} className={classes.left}>
                <section className={classes.header}>
                  <div className={classes.headerLeft}>
                    <Avatar src={company.logo} className={classes.avatar} />
                  </div>
                  <div className={classes.headerRight}>
                    <Typography variant="h5">
                      {company.nome_fantasia}
                    </Typography>
                    {address && (
                      <Typography variant="capition" className={classes.blue}>
                        <i className={classes.gpsLocal}>
                          <LocationOnIcon fontSize="small" />
                        </i>
                        <p className={classes.addressSiple}>
                          {`${address.city} | ${address.state}`}
                        </p>
                      </Typography>
                    )}
                  </div>
                </section>
                <Grid spacing={1} container>
                  <Grid
                    item
                    style={{ display: 'flex' }}
                    alignItems="center"
                    md={6}
                    xs={6}
                  >
                    <section className={classes.infos}>
                      <Typography
                        variant="h5"
                        component="span"
                        className={classes.blue}
                      >
                        Posição:{' '}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="span"
                        className={classes.gray}
                      >
                        {info.position}
                      </Typography>
                      <div className={classes.infosjobs}>
                        <Typography variant="h6" className={classes.blue}>
                          Pagamento total:{' '}
                        </Typography>
                        <Typography variant="h6" className={classes.gray}>
                          {currencyFormat(info.salary)}
                        </Typography>
                      </div>
                    </section>
                  </Grid>
                  <Grid item md={6} xs={6} className={classes.imageContent}>
                    <div className={classes.headerInfo}>
                      <Typography
                        variant="body1"
                        component="p"
                        className={classes.blue}
                        style={{ fontWeight: 'bold', marginBottom: '-6px' }}
                      >
                        {info.type_opportunity.toUpperCase()}
                      </Typography>
                      {/* <Typography
                          variant="body1"
                          component="p"
                          className={classes.green}
                          style={{ fontWeight: 'bold' }}
                        >
                          BAR
                        </Typography> */}
                    </div>
                    <div className={classes.image}>
                      {company.images?.length ? (
                        <img src={company.images[0].image} alt="foto" />
                      ) : (
                        <ImageOutlinedIcon className={classes.imageEmpty} />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <section className={classes.bottom}>
              <Grid container className={classes.details}>
                <Grid item md={6} xs={12} className={classes.localization}>
                  <Typography variant="caption" component="p">
                    Localização
                  </Typography>
                  <Typography variant="caption" className={classes.blue}>
                    <i className={classes.gpsLocal}>
                      <LocationOnIcon fontSize="large" />
                    </i>
                    <p className={classes.addressLocalization}>
                      {formatShortAddress(address)}
                    </p>
                  </Typography>
                </Grid>
                {(info.health_insurance
                  || info.snack_ticket
                  || info.transport_ticket) && (
                    <Grid item md={6} xs={12} className={classes.benefit}>
                      <Typography variant="caption" component="p">
                        Benefícios
                      </Typography>
                      {info.health_insurance && (
                        <div className={classes.iconBene}>
                          <i className={classes.shield}>
                            <VerifiedUserOutlinedIcon
                              fontSize="large"
                              style={{ color: '#4DC3F0' }}
                            />
                          </i>
                          <p>Seguro de vida</p>
                        </div>
                      )}
                      {info.snack_ticket && (
                        <div className={classes.iconBene}>
                          <i className={classes.fork}>
                            <SpoonForkCrossedIcon />
                          </i>
                          <p>Vale refeição</p>
                        </div>
                      )}
                      {info.transport_ticket && (
                        <div className={classes.iconBene}>
                          <i className={classes.bus}>
                            <BusIcon />
                          </i>
                          <p style={{ marginTop: '2px' }}>Vale Trasporte</p>
                        </div>
                      )}
                    </Grid>
                )}
              </Grid>
              <Grid container spacing={1} className={classes.buttons}>
                <Grid item md={6} xs={12} className={classes.greenButton}>
                  <GreenButton
                    onClick={() => shareOpportunity({ ...info, ...company })}
                    size="large"
                    variant="contained"
                  >
                    <WhatsAppIcon
                      style={{
                        position: 'relative',
                        top: '-1px',
                        marginRight: '2px',
                      }}
                    />
                    COMPARTILHAR
                  </GreenButton>
                </Grid>
                <Grid item md={6} xs={12} className={classes.blueButton}>
                  <BlueButton
                    onClick={() => handleFeedCandidate(info)}
                    size="large"
                    variant="contained"
                    disabled={info.applied}
                  >
                    ME CANDIDATAR
                  </BlueButton>
                </Grid>
                {info.applied && (
                  <>
                    <Grid item md={6} xs={12} />
                    <Grid item md={6} xs={12}>
                      <Box textAlign="center">
                        <Typography color="primary" variant="caption">
                          Você já se candidatou a esta vaga
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </section>
            <ModalConfirm
              open={!!opportunity}
              onClose={() => setOpportunity(null)}
              onSuccess={handleCandidateApply}
              successText="Candidatar-se"
              title="Confirme sua candidatura"
              body=""
              companyName={company.nome_fantasia}
              position={info.position}
              salary={info.salary}
            />
          </section>
        ))
      ) : (
        <section className={classes.whiteBg}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={8} sm={8} md={8}>
              <Box textAlign="center">
                <Typography variant="h5">
                  Não estamos localizando vagas para essa posição na sua região
                </Typography>
                <Typography variant="body2">
                  Mas não se preocupe, vamos informar você assim que surgirem
                  novas oportunidades
                </Typography>
              </Box>
            </Grid>
            <EmptyMessageFeedIcon />
          </Grid>
        </section>
      )}
      <ModalSuccessOpportunity
        open={showModalSuccess}
        onClose={() => setShowModalSuccess(false)}
      />
      <ModalFailOpportunity
        open={showModalFail}
        onClose={() => setShowModalFail(false)}
      />
    </section>
  )
}

FeedCandidate.propTypes = {
  setOpportunity: PropTypes.func.isRequired,
  shareOpportunity: PropTypes.func.isRequired,
  loadingOpportunity: PropTypes.bool.isRequired,
  handleCandidateApply: PropTypes.func.isRequired,
  opportunity: PropTypes.objectOf(PropTypes.object).isRequired,
  listOpportunityBoard: PropTypes.arrayOf(PropTypes.array).isRequired,
}

export default FeedCandidate
