import React, { useState } from 'react'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import AppsIcon from '@material-ui/icons/Apps'
import useStyles from './style'

const ButtonPosition = ({ positions, setPositionsSelected }) => {
  const classes = useStyles()
  const [valueSelected, setValueSelected] = useState(positions?.map(({ id }) => id).join(','))

  return (
    <Grid container spacing={3} className={classes.contentFilter}>
      {
        !!positions.length && (
          <Grid item className={classes.itemPosition}>
            <button
              type="button"
              className={positions?.map(({ id }) => id).join(',') == valueSelected ? classes.buttonSelected : classes.button}
              value={positions?.map(({ id }) => id).join(',')}
              onClick={(e) => {
                setPositionsSelected(e.currentTarget.value)
                setValueSelected(e.currentTarget.value)
              }}

            >
              <AppsIcon className={classes.iconFilter} fontSize="large" />
            </button>
            <Typography className={[classes.namePosition, classes.white]} variant="h6">TODOS</Typography>

          </Grid>
        )
      }
      {
        positions.map((position) => (
          <Grid item className={classes.itemPosition}>
            <button
              type="button"
              className={position.id == valueSelected ? classes.buttonSelected : classes.button}
              value={position.id}
              onClick={(e) => {
                setPositionsSelected(e.currentTarget.value)
                setValueSelected(e.currentTarget.value)
              }}
            >
              <AppsIcon className={classes.iconFilter} fontSize="large" />
            </button>
            <Typography className={[classes.namePosition, classes.white]} variant="h6">{position.name.toUpperCase()}</Typography>

          </Grid>
        ))
      }

    </Grid>
  )
}

export default ButtonPosition
