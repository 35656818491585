import React from 'react';

export default function RocketGreenIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" id="start-button_1_" data-name="start-button (1)" width="72.6" height="72.6" viewBox="0 0 72.6 72.6" {...props}>
        <path id="Path_9885" data-name="Path 9885" d="M72.515,5.42A5.643,5.643,0,0,0,67.18.085C61.361-.233,49.974.077,39.086,5.708A55.155,55.155,0,0,0,22.98,19.064l-.171.208-10.632.821A5.613,5.613,0,0,0,7.9,22.609L.647,33.581a3.888,3.888,0,0,0,2.648,5.986l8.957,1.387c-.032.174-.063.348-.092.522a5.64,5.64,0,0,0,1.581,4.906L26.218,58.859a5.654,5.654,0,0,0,3.99,1.657,5.56,5.56,0,0,0,.916-.076c.174-.029.348-.061.522-.092l1.387,8.958a3.884,3.884,0,0,0,5.986,2.648L49.99,64.7a5.612,5.612,0,0,0,2.516-4.277l.821-10.632.208-.171A55.15,55.15,0,0,0,66.891,33.514c5.632-10.889,5.941-22.276,5.623-28.094ZM47.644,61.151,37.15,68.09,35.8,59.36a47.648,47.648,0,0,0,13-6.191L48.266,60.1a1.386,1.386,0,0,1-.621,1.056Zm-18.419-5.3L16.749,43.374a1.379,1.379,0,0,1-.393-1.2,36.59,36.59,0,0,1,1.661-6.319L36.737,54.58a36.744,36.744,0,0,1-6.315,1.665,1.379,1.379,0,0,1-1.2-.393ZM12.5,24.334,19.43,23.8a47.644,47.644,0,0,0-6.191,13L4.51,35.45l6.939-10.495A1.386,1.386,0,0,1,12.5,24.334ZM50.812,46.353a48.766,48.766,0,0,1-9.846,6.439L19.806,31.634a49.61,49.61,0,0,1,6.44-9.845A50.76,50.76,0,0,1,41.04,9.487c9.993-5.169,20.522-5.447,25.908-5.154a1.4,1.4,0,0,1,1.319,1.319c.294,5.387.015,15.915-5.154,25.908A50.76,50.76,0,0,1,50.812,46.353Z" transform="translate(0 0)" fill="#1FE390"/>
        <path id="Path_9886" data-name="Path 9886" d="M286.357,107.577a10.626,10.626,0,1,0-7.52-3.11A10.6,10.6,0,0,0,286.357,107.577Zm-4.512-15.141a6.38,6.38,0,1,1-1.869,4.512A6.36,6.36,0,0,1,281.846,92.436Z" transform="translate(-236.626 -74.079)" fill="#1FE390"/>
        <path id="Path_9887" data-name="Path 9887" d="M2.428,354.163a2.119,2.119,0,0,0,1.5-.623l6.944-6.944a2.127,2.127,0,0,0-3.008-3.008L.924,350.532a2.127,2.127,0,0,0,1.5,3.631Z" transform="translate(-0.259 -294.333)" fill="#1FE390"/>
        <path id="Path_9888" data-name="Path 9888" d="M16.982,388.469a2.127,2.127,0,0,0-3.008,0L.623,401.819a2.127,2.127,0,1,0,3.008,3.008l13.351-13.351A2.126,2.126,0,0,0,16.982,388.469Z" transform="translate(0 -332.85)" fill="#1FE390"/>
        <path id="Path_9889" data-name="Path 9889" d="M97.63,433.349l-6.944,6.944a2.127,2.127,0,1,0,3.008,3.008l6.944-6.944a2.127,2.127,0,0,0-3.008-3.008Z" transform="translate(-77.292 -371.367)" fill="#1FE390"/>
      </svg>
    );
}
