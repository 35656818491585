import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  contentFilter: {
    justifyContent: "space-evenly",
    marginBottom: "30px",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  white: {
    color: "#fff",
  }, 
  button: {
    background: "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    width: "75px",
    borderRadius: "50%",
    border: "3px solid #41C0F0",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonSelected: {
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    width: "75px",
    borderRadius: "50%",
    border: "3px solid #41C0F0",
  },
  iconFilter: {
    color: "#fff",
    fontSize: "42px",
  },
  namePosition: {
    paddingTop: "10px",
    fontSize: "16px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
  },
  itemPosition: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default style;
