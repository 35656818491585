import React from "react";

export default function CheckCircleIcon() {
  return (
    <svg
      width="91"
      height="90"
      viewBox="0 0 91 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.1267 29.159H59.455C58.439 29.159 57.4728 29.6471 56.8751 30.4838L41.2165 52.1986L34.1243 42.3572C33.5267 41.5305 32.5704 41.0324 31.5444 41.0324H26.8728C26.2253 41.0324 25.8468 41.7695 26.2253 42.2975L38.6366 59.51C38.9298 59.9192 39.3163 60.2526 39.7641 60.4827C40.2119 60.7127 40.7081 60.8327 41.2115 60.8327C41.7149 60.8327 42.2111 60.7127 42.6589 60.4827C43.1067 60.2526 43.4932 59.9192 43.7864 59.51L64.7642 30.424C65.1526 29.8961 64.7741 29.159 64.1267 29.159Z"
        fill="#35BFEE"
      />
      <path
        d="M45.5 0.375C20.8566 0.375 0.875 20.3566 0.875 45C0.875 69.6434 20.8566 89.625 45.5 89.625C70.1434 89.625 90.125 69.6434 90.125 45C90.125 20.3566 70.1434 0.375 45.5 0.375ZM45.5 82.0547C25.0402 82.0547 8.44531 65.4598 8.44531 45C8.44531 24.5402 25.0402 7.94531 45.5 7.94531C65.9598 7.94531 82.5547 24.5402 82.5547 45C82.5547 65.4598 65.9598 82.0547 45.5 82.0547Z"
        fill="#35BFEE"
      />
    </svg>
  );
}
