export function isLastItem(list = [], index = 0) {
  return list.length == (index + 1)
}

/**
 * @number value
 * @returns Locale string of number
 */
export function currencyFormat(value) {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}
