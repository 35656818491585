import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { fade, withStyles, Typography, Grid } from "@material-ui/core";
import { currencyFormat } from "providers/utils";
import useStyles from "./style";

const BlueButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#41C0F0",
    color: theme.palette.success.contrastText,
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    marginLeft: "10px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#0B2C46",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      "& span": {
        fontSize: "12px",
      },
    },
  },
}))(Button);

const BlueButtonOutlined = withStyles((theme) => ({
  root: {
    color: "#41C0F0",
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      "& span": {
        fontSize: "12px",
      },
    },
  },
}))(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalConfirm({
  open,
  onClose,
  title,
  body,
  onSuccess,
  successText = "Confirmar",
  companyName,
  position,
  salary,
}) {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="confirm-dialog-description"
    >
      <div>
        <div className={classes.container}>
          <Typography variant="h5" className={classes.titleModal}>
            {title}
          </Typography>
        </div>

        <DialogContent className={classes.content}>
          <Typography variant="h6" className={classes.title}>
            {companyName}
          </Typography>
          <div className={classes.row}>
            <Typography variant="h6" className={classes.title}>
              Posição:{" "}
            </Typography>
            <Typography variant="h6" className={classes.titlePosition}>
              {position}
            </Typography>
          </div>
          <div>
            <Typography className={classes.paymentTotal}>
              Pagamento Total:
            </Typography>
            <Typography className={classes.paymentValue}>
              {currencyFormat(Number(salary))}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container className={classes.buttonContainer}>
            <Grid item md={6} xs={12} className={classes.blueButton}>
              <BlueButtonOutlined
                size="large"
                onClick={onClose}
                variant="outlined"
                color="primary"
              >
                Cancelar
              </BlueButtonOutlined>
            </Grid>
            <Grid item md={6} xs={12} className={classes.blueButton}>
              <BlueButton onClick={onSuccess} size="large" variant="contained">
                ME CANDIDATAR
              </BlueButton>
            </Grid>
          </Grid>
        </DialogActions>
      </div>
    </Dialog>
  );
}
