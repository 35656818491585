/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { service } from "services/index";
import useOpportunity from "containers/opportunity/hooks/useOppotunity";
import FeedCandidate from "./Feed";

const Container = ({ company, positions }) => {
  const [listOpportunityBoard, setListOpportunityBoard] = useState([]);
  const [loadingOpportunity, setLoadingOpportunity] = useState(false);
  const [opportunity, setOpportunity] = useState(null);
  const [positionsSelected, setPositionsSelected] = useState([]);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalFail, setShowModalFail] = useState(false);
  const {
    opportunities,
    loading,
    setLoading,
    error,
    listByCandidate,
    errorSeeOpportunities,
  } = useOpportunity();

  const profileUnfinished =
    error?.message === "Você não tem permissão para executar essa ação.";

  const handleListOpportunityBoard = () => {
    const params = {
      page_size: 3,
      page: 1,
      positions: positions?.map(({ id }) => id).join(","),
      specializations: null,
      company,
    };

    listByCandidate(params);
  };

  const handleCandidateApply = () => {
    const payload = {
      origin: 'current',
      origin_detail: {
        page: 'opportunities-feed',
      },
    }

    service.company
      .assignCandidateToOpportunity(opportunity.uuid, payload)
      .then(() => {
        setOpportunity(null)
        setShowModalSuccess(true)
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      })
      .catch((e) => {
        setOpportunity(null)
        setShowModalFail(true)
        console.error(e)
      })
  };

  const shareOpportunity = ({ id, position, slug }) => {
    const text = {
      title: `Oportunidade para ${position}`,
      message: "Acesse o link para ver mais detalhes sobre a vaga",
      link: `${window.location.host}/${slug}/vagas/${id}`,
      obs: "Time WORC!",
    };

    Object.keys(text).forEach((key) => {
      text[key] = text[key].concat("%0A").replace(/\s/g, "%20");
    });

    window.open(
      `https://wa.me/?text=${text.title}${text.message}${text.link}${text.obs}`,
      "_blank"
    );
  };

  function handleGetOpportunities() {
    setLoading(true);

    const positionsFilter = positionsSelected?.length
      ? positionsSelected
      : undefined;

    const params = {
      page_size: 5,
      page: 1,
      positions: positionsFilter,
      specializations: null,
      company,
    };

    listByCandidate(params);
  }

  useEffect(() => {
    handleListOpportunityBoard();
  }, []);

  useEffect(() => {
    handleGetOpportunities();
  }, [positionsSelected]);

  return (
    <FeedCandidate
      profileUnfinished={profileUnfinished}
      opportunity={opportunity}
      setOpportunity={setOpportunity}
      shareOpportunity={shareOpportunity}
      loadingOpportunity={loadingOpportunity}
      listOpportunityBoard={opportunities}
      handleCandidateApply={handleCandidateApply}
      positions={positions}
      setPositionsSelected={setPositionsSelected}
      showModalSuccess={showModalSuccess}
      setShowModalSuccess={setShowModalSuccess}
      showModalFail={showModalFail}
      setShowModalFail={setShowModalFail}
    />
  );
};

Container.propTypes = {
  company: PropTypes.number,
  positions: PropTypes.arrayOf(PropTypes.number),
};

Container.defaultProps = {
  company: null,
  positions: null,
};

export default Container;
