import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "32px",
  },
  title: {
    fontWeight: "bold",
    marginTop: "16px",
  },
  body: {
    display: "flex",
    justifyContent: "center",
  },
  contentBody: {
    width: "70%",
  },
  titlePosition: {
    fontWeight: "bold",
    fontSize: "14px",
    opacity: 0.4,
    marginTop: "16px",
    marginBottom: "16px",
    textAlign: "center",
  },
}));

export default style;
