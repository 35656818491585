import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles((theme) => ({
  main: {
    backgroundColor: '#0B2C46',
    borderRadius: '12px',
    padding: theme.spacing(3),
    color: '#000',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  mainTitle: {
    fontSize: 30,
    paddingBottom: theme.spacing(3),
    fontWeight: 'bold',
    color: '#0b2c46',
  },
  subTitle: {
    fontSize: 25,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(1),
  },
  white: {
    color: '#fff',
  },
  content: {
    overflow: 'hidden',
    marginBottom: '10px',
  },
  left: {
    overflow: 'hidden',
  },
  imageContent: {
    postion: 'relative',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1),
    },
  },
  bottom: {
    overflow: 'hidden',
  },
  details: {
    overflow: 'hidden',
  },
  buttons: {
    overflow: 'hidden',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  header: {
    overflow: 'hidden',
    marginBottom: '25px',
    marginTop: '15px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
    },
  },
  avatar: {
    border: '2px solid #41C0F0',
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: '0 auto 10px auto',
    },
  },
  whiteBg: {
    position: 'relative',
    backgroundColor: '#FFF',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  headerLeft: {
    float: 'left',
    marginRight: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      float: 'initial',
    },
  },
  headerRight: {
    marginTop: '10px',
    overflow: 'hidden',
    '& h5': {
      maxWidth: '210px',
    },
    '& span': {
      maxWidth: '210px',
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
      '& h5': {
        textAlign: 'center',
        fontSize: '20px',
        margin: '0 auto',
      },
      '& span': {
        fontSize: '14px',
        textAlign: 'center',
        justifyContent: 'center',
        margin: '0 auto',
      },
    },
  },
  green: {
    color: '#1FE390',
  },
  blue: {
    color: '#41C0F0',
  },
  gray: {
    color: '#6F6F6F',
  },
  imageEmpty: {
    fontSize: 150,
    color: '#ccc',
  },
  infos: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      '& span': {
        fontSize: '14px',
      },
    },
  },
  infosjobs: {
    overflow: 'hidden',
    marginTop: '15px',
    [theme.breakpoints.down('sm')]: {
      '& h6': {
        fontSize: '14px',
      },
    },
  },
  headerInfo: {
    overflow: 'hidden',
    marginBottom: '20px',
    lineHeight: '20px',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      '& h6': {
        fontSize: '14px',
      },
    },
  },
  image: {
    clear: 'both',
    textAlign: 'right',
    '& img': {
      width: '80%',
      borderRadius: '10px',
      maxWidth: '250px',
    },
  },
  localization: {
    width: '50%',
    overflow: 'hidden',
    '& p': {
      fontSize: '16px',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '20px',
      },
      '& i': {
        height: '35px',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  benefit: {
    width: '50%',
    overflow: 'hidden',
    paddingTop: 0,
    paddingBottom: 0,
    '& p': {
      textAlign: 'left',
      fontSize: '14px',
      marginBottom: 16,
    },
  },
  iconBene: {
    float: 'left',
    textAlign: 'center',
    width: '33%',
    '& p': {
      fontSize: '12px',
      marginTop: '8px',
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: '10px',
        marginTop: '8px',
      },
    },
  },
  addressLocalization: {
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    height: '35px',
  },
  addressSiple: {
    fontSize: '14px',
    textDecoration: 'underline',
    position: 'relative',
    top: '2px',
  },
  shield: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        height: '20px',
        width: '20px',
      },
    },
  },
  bus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: '40px',
      width: '40px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-4px',
      '& svg': {
        height: '30px',
        width: '30px',
      },
    },
  },
  fork: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        height: '20px',
        width: '20px',
      },
    },
  },
  emptyPosition: {
    justifyContent: 'center',
    display: 'flex',
  },
}));

export default style;
