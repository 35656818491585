import React from "react";

export default function FailCircleIcon() {
  return (
    <svg
      width="91"
      height="90"
      viewBox="0 0 91 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.7709 29.2477C62.7709 28.8094 62.4123 28.4508 61.974 28.4508L55.3998 28.4807L45.4986 40.2844L35.6074 28.4907L29.0232 28.4608C28.5849 28.4608 28.2263 28.8094 28.2263 29.2577C28.2263 29.4469 28.2961 29.6262 28.4156 29.7756L41.3748 45.2151L28.4156 60.6446C28.2952 60.7906 28.2284 60.9734 28.2263 61.1625C28.2263 61.6008 28.5849 61.9594 29.0232 61.9594L35.6074 61.9295L45.4986 50.1258L55.3898 61.9196L61.964 61.9495C62.4023 61.9495 62.7609 61.6008 62.7609 61.1526C62.7609 60.9633 62.6912 60.784 62.5716 60.6346L49.6324 45.2051L62.5916 29.7657C62.7111 29.6262 62.7709 29.437 62.7709 29.2477Z"
        fill="#F51F7E"
      />
      <path
        d="M45.5 0.375C20.8566 0.375 0.875 20.3566 0.875 45C0.875 69.6434 20.8566 89.625 45.5 89.625C70.1434 89.625 90.125 69.6434 90.125 45C90.125 20.3566 70.1434 0.375 45.5 0.375ZM45.5 82.0547C25.0402 82.0547 8.44531 65.4598 8.44531 45C8.44531 24.5402 25.0402 7.94531 45.5 7.94531C65.9598 7.94531 82.5547 24.5402 82.5547 45C82.5547 65.4598 65.9598 82.0547 45.5 82.0547Z"
        fill="#F51F7E"
      />
    </svg>
  );
}
